import React from "react"
import Layout from "../layouts"
import SEO from "../components/SEO"
import { PageProps } from "../commonTypes"

const NotFoundPage = (props: PageProps) => (
  <Layout lang="ja" location={props.location}>
    <SEO title="404: Not found" ogImage={undefined} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
